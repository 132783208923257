<template>
    <div :class="direction+'-course-item'" @click="toDetail">
        <div style="position: relative;">
            <img class="cover" :src="item.imageUrl"/>
            <div class="view-count">
                <img src="../assets/img/home/video-play.png"/>
                <span>{{item.count}}</span>
            </div>
        </div>
        <div class="video-content">
        <div class="title">{{item.title}}</div>
        <div class="video-count">共{{item.videoCount}}节课</div>
       </div>
    </div>
</template>

<script>
    export default {
        name: "CourseItem",
        props:{
            item:{
                type:Object,
                default:function () {

                    return {}
                }
            },
            direction:{
                type:String,
                default:'vertical'
            }
        },

        methods:{
            toDetail(){

                this.$router.push({path:`/courseDetail/${this.item.id}`})

            }
        }
    }
</script>

<style lang="less" scoped>

    .vertical-course-item{
        background-color: #fff;
        box-shadow: 0px 3px 24px 1px rgba(126,142,177,0.2);
        border-radius: 6px;
        overflow: hidden;
        cursor: pointer;

            &:hover {

                .title{
                    color: #3B89FF;
                }
            }


        .cover{
            width: 100%;
            height: 200px;
            background-color: #F3F4F6;
        }
        .view-count{
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: flex;
            padding: 2px 5px;
            align-items: center;
            background-color: rgba(0,0,0,0.3);
            border-radius: 2px;
            img{
                width: 16px;
                height: 13px;
            }
            span{
                color: #fff;
                font-size: 12px;
                margin-left: 5px;

            }

        }
        .video-content{

            padding: 0 10px 10px 10px;
        .title{
            font-size: 15px;
        }
        .video-count{
            margin-top: 9px;
            font-size: 13px;
            color: rgba(60,60,67,0.6);
        }
    }
    }
    .horizontal-course-item{
        background-color: #fff;
        display: flex;
        cursor: pointer;

            &:hover {

                .title{
                    color: #3B89FF;
                }
            }


        .cover{
            width: 160px;
            height: 90px;
            border-radius: 6px;
            background-color: #F3F4F6;
        }

      
        .view-count{
            position: absolute;
            bottom: 10px;
            left: 10px;
            display: flex;
            padding: 2px 5px;
            align-items: center;
            background-color: rgba(0,0,0,0.3);
            border-radius: 2px;
            img{
                width: 16px;
                height: 13px;
            }
            span{
                color: #fff;
                font-size: 12px;
                margin-left: 5px;

            }

        }

        .video-content{
            margin-left: 10px;
      
        .title{
            font-size: 15px;
            overflow: hidden;
            text-overflow: ellipsis;
            word-break: break-all;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            -webkit-box-orient: vertical;
        }
        .video-count{
            margin-top: 9px;
            font-size: 13px;
            color: rgba(60,60,67,0.6);
        }
       }
    }
</style>
