<template>
    <div class="course">
        <Header/>
        <div class="banner">
            <div class="nav">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
                    <el-breadcrumb-item>视频学习</el-breadcrumb-item>
                </el-breadcrumb>

                <div class="search-bar">
                    <el-input v-model="key" placeholder="请输入课程相关关键词进行搜索" clearable @clear="search">
                        <el-button slot="append" type="primary" @click="search">搜索</el-button>
                    </el-input>
                </div>
            </div>
        </div>
        <div class="course-content">
            <div class="main">
                <div class="filter-bar">
                 <div class="filter">
                    <div class="label">行业领域：</div>
                    <div class="filter-list is-expand">
                        <div :class="industryActive===index?'filter-item active':'filter-item'" v-for="(item,index) in categories" :key="index" @click="industryItemClick(index)">{{ item.name}}</div>
                    </div>
                 </div>
                 <div class="filter">
                    <div class="label">
                        学习科目：
                    </div>

                    <div :class="expand?'filter-list is-expand':'filter-list expand-tag'">
                        <div :class="subjectActive===index?'filter-item active':'filter-item'" v-for="(item,index) in subjectList" :key="index" @click="subjectItemClick(index)">{{ item.name}}</div>
                    </div>
                     <div class="expand" @click="onExpand" v-if="showExpand">
                        <i :class="expand?'el-icon-arrow-up':'el-icon-arrow-down'"></i>
                     </div>

                    </div>

                </div>
                 <div class="filter-sort">
                    <div class="sort-list">
                        <div :class="item.type===sortType?'sort-item active':'sort-item'" v-for="(item,index) in sortList" :key="index" @click="sort(item.type)">{{ item.title }}</div>
                    </div>
                    <div class="count">共找到<span>{{ total }}</span>门课程</div>
                 </div> 
                 <div class="course-list">
                <div  v-for="(item,index) in list" :key="index" @click="playcourse(item)">
                <course-item :item="item" ></course-item>
                </div>
                 </div>
                <empty height="400px" v-if="empty"/>
                <div class="page">
                    <el-pagination
                            :hide-on-single-page="true"
                            @current-change="handelChange"
                            background
                            :page-size="pageSize"
                            layout="total, prev, pager, next"
                            :total="total">
                    </el-pagination>
                </div>
            </div>

        </div>
        <Footer/>
       
    </div>
</template>

<script>
import Header from "../components/Header.vue"
import CourseItem from '@/components/CourseItem.vue'
import Empty from '@/components/Empty'
import Footer from "../components/Footer.vue"
 
import {GetCourseList} from '../api/list'
    export default {
        components: {
            CourseItem,
            Header,
            Empty,
            Footer
        },
        data() {
            return {
                expand:false,
                showExpand:false,
                categories:[{id:'0',name:'全部'}],
                industryActive:-1,
                subjectList:[],
                subjectActive:0,
                categoryId:'',
                total:1,
                list:[],
                empty:false,
                pageSize:12,
                page:1,
                key:'',
                sortType:'0',
                sortList:[{
                    type:'0',
                    title:'综合'
                },
                {
                    type:'1',
                    title:'人气'
                },
            ]

            }
        },

        created () {


            this.getCourseData()

            //监听科目改变
            this.bus.$on(this.ON_CATEGORY_CHANGE,()=>{
                this.getCourseData()

            })

        },

        mounted(){
           this.categories=this.categories.concat(this.$store.state.category.categories) 
           this.industryItemClick(0)
           this.isShowExpand()
        },

        methods: {

            //行业切换
            industryItemClick(index){

              if(this.industryActive===index){
                return
              }  
              this.industryActive=index;
              this.subjectActive=0;
              this.subjectList=[{id:'0',name:'全部'}]
              //全部
              if(this.categories[index].id==='0'){

             for(let item of this.categories){
                 if(item.children){
                 this.subjectList=this.subjectList.concat(item.children)
                }
              }


              }else{
                this.subjectList=this.subjectList.concat(this.categories[index].children)
              }
            },
            //科目切换
            subjectItemClick(index){
                if(this.subjectActive===index){
                return
              }  
              this.subjectActive=index;

             let id= this.subjectList[index].id
             this.categoryId=id==='0'?'':id;
              this.page=1
              this.getCourseData()

            },

            //搜索
            search(){

               this.page=1;
               this.getCourseData()
            },

            //加载视频数据
            getCourseData(){
                GetCourseList(this.page,this.pageSize,this.categoryId,this.key,this.sortType).then(res=>{
                    this.total=res.total;
                    this.list=res.data
                    this.empty=this.list.length===0
                });

            },

            //判断是否显示展开
            isShowExpand(){

                let dom = document.querySelector('.expand-tag')
                this.showExpand=dom.offsetWidth>1000;
            },

            //展开更多学习科目
            onExpand(){

                this.expand=!this.expand

            },

            //排序
            sort(type){

               this.sortType=type;
            
               this.getCourseData()

            },

            playcourse(item){

                this.courseId=item.id;
                this.poster=item.coverImg;
                this.courseSrc=item.url;
                this.courseDialog=true;

            },


            HandelJump(item){
                this.$store.commit('course/courseInfoMut',item)
                this.$router.push({
                    path:'/course/coursedetail',
                    query:{
                        url:item.url,
                        title:item.title,
                        id:item.id

                    },

                })
            },
            handelto(i) {
                this.$router.push('/'+i)
            },
            handelGo(){
                this.$fullscreen.enter()
                this.$router.push({
                    path:'/exam',
                    name:'Exam',
                })
            },
            handelChange(val){
                this.page=val
                this.getcourseData()
            },
        },
        beforeDestroy(){
            //解绑
            this.bus.$off(this.ON_CATEGORY_CHANGE)
        }
    }
</script>

<style lang="less" scoped>
@import url(../assets/css/Course.css);

  .course{
    .nav{
    /deep/ .el-breadcrumb__inner {
    color: #fff;
  }

  .search-bar{

    /deep/ .el-input-group__append, .el-input-group__prepend{

        background-color: #3B89FF;
        border: none;
        color: #fff;
    }
  }

}

  }

</style>
